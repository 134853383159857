'use client';

import { createContext, ReactNode, useEffect } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { usePathname, useSearchParams } from 'next/navigation';

const analytics = AnalyticsBrowser.load({
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY ?? '',
});

interface Context {
  analytics: AnalyticsBrowser;
}

export const AnalyticsContext = createContext<Context>({
  analytics,
});

const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    void analytics.page(`[WA] ${pathname}`);
  }, [pathname, searchParams]);

  return (
    <AnalyticsContext.Provider value={{ analytics }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
