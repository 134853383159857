import legacyPalette from '@legacy-components/theme/colors/palette';

const palette = {
  ...legacyPalette,
  common: {
    black: '#1E1F20',
    white: '#FFFFFF',
  },
  orange: {
    pastel: '#F8E2D5',
    medium: '#F5A46E',
    main: '#FE6F46',
    contrastText: '#FFFFFF',
  },
  green: {
    pastel: '#9FCFCA',
    medium: '#57B6B2',
    main: '#006463',
  },
  yellow: {
    pastel: '#FFEAAD',
    medium: '#FDDA64',
    main: '#FDC500',
    contrastText: '#1E1F20',
  },
  purple: {
    pastel: '#C5B4E2',
    medium: '#946BAC',
    main: '#510C76',
  },
  blue: {
    pastel: '#BDC5E6',
    medium: '#748FC9',
    main: '#1226AA',
  },
  black: {
    pastel: '#9E9E9E',
    medium: '#616161',
    main: '#1E1F20',
    contrastText: '#FFFFFF',
  },
  grey: {
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
  },
  background: {
    paperVariant: '#F5F5F5',
  },
};

export default palette;
