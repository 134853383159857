import { Color } from '@legacy-components/theme';
import { ButtonProps, createTheme, PaletteColor, Theme } from '@mui/material';

import palette from '@app/theme/palette';

import shadows from './shadows';
import typography from './typography';
import { getButtonColor, getContrastTextColor } from './utils';

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  export interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  type ColorShade = 'main' | 'medium' | 'pastel';

  interface Theme {
    sizing: {
      tabHeight: {
        sm: string;
        lg: string;
      };
    };
  }

  interface ThemeOptions {
    sizing?: {
      tabHeight?: {
        sm: string;
        lg: string;
      };
    };
  }

  interface PaletteColor {
    medium: string;
    pastel: string;
    mediumContrastText: string;
    pastelContrastText: string;
  }

  interface TypeBackground {
    paperVariant: string;
  }

  interface SimplePaletteColorOptions {
    medium: string;
    pastel: string;
  }

  interface Palette {
    /* LEGACY START */
    [Color.PRIMARY]: PaletteColor;
    [Color.SECONDARY]: PaletteColor;
    [Color.TERTIARY]: PaletteColor;
    [Color.QUATERNARY]: PaletteColor;
    [Color.DANGER]: PaletteColor;
    [Color.DANGER_ALT]: PaletteColor;
    [Color.SUCCESS]: PaletteColor;
    [Color.SUCCESS_ALT]: PaletteColor;
    [Color.SUNNY]: PaletteColor;
    [Color.LIGHT]: PaletteColor;
    [Color.PRIMARY_ALT]: PaletteColor;
    [Color.SECONDARY_ALT]: PaletteColor;
    [Color.TERTIARY_ALT]: PaletteColor;
    [Color.QUATERNARY_ALT]: PaletteColor;
    [Color.SUNNY_ALT]: PaletteColor;
    [Color.DARK_ALT]: PaletteColor;
    [Color.GRAY_100]: PaletteColor;
    [Color.GRAY_200]: PaletteColor;
    [Color.GRAY_300]: PaletteColor;
    [Color.GRAY_400]: PaletteColor;
    [Color.GRAY_500]: PaletteColor;
    [Color.DARK]: PaletteColor;
    [Color.DISABLED]: PaletteColor;
    [Color.POULTRY]: PaletteColor;
    [Color.GLUTEN_FREE]: PaletteColor;
    [Color.RED_MEAT]: PaletteColor;
    [Color.SEA_SHELL]: PaletteColor;
    [Color.LACTOSE_FREE]: PaletteColor;
    [Color.PORK]: PaletteColor;
    [Color.RABBIT]: PaletteColor;
    [Color.VEGGIE]: PaletteColor;
    [Color.SPICY]: PaletteColor;
    [Color.ALCOHOL]: PaletteColor;
    [Color.TWITTER]: PaletteColor;
    [Color.INSTAGRAM]: PaletteColor;
    [Color.FACEBOOK]: PaletteColor;
    [Color.TABLE_ROW]: PaletteColor;
    [Color.PRIMARY_LIGHT]: PaletteColor;
    [Color.SECONDARY_LIGHT]: PaletteColor;
    [Color.TERTIARY_LIGHT]: PaletteColor;
    [Color.QUATERNARY_LIGHT]: PaletteColor;
    [Color.SUNNY_LIGHT]: PaletteColor;
    [Color.DANGER_LIGHT]: PaletteColor;
    [Color.SUCCESS_LIGHT]: PaletteColor;
    [Color.DARK_LIGHT]: PaletteColor;
    [Color.DISABLED_LIGHT]: PaletteColor;
    [Color.POULTRY_LIGHT]: PaletteColor;
    [Color.GLUTEN_FREE_LIGHT]: PaletteColor;
    [Color.RED_MEAT_LIGHT]: PaletteColor;
    [Color.SEA_SHELL_LIGHT]: PaletteColor;
    [Color.LACTOSE_FREE_LIGHT]: PaletteColor;
    [Color.PORK_LIGHT]: PaletteColor;
    [Color.RABBIT_LIGHT]: PaletteColor;
    [Color.VEGGIE_LIGHT]: PaletteColor;
    [Color.SPICY_LIGHT]: PaletteColor;
    [Color.ALCOHOL_LIGHT]: PaletteColor;
    [Color.EXPIRATION_SALE_BACKGROUND]: PaletteColor;
    danger: PaletteColor;
    light: PaletteColor;
    poultry: PaletteColor;
    /* LEGACY END */

    primary: PaletteColor;

    orange: PaletteColor;
    green: PaletteColor;
    yellow: PaletteColor;
    purple: PaletteColor;
    blue: PaletteColor;
    black: PaletteColor;
  }

  interface PaletteOptions {
    orange: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    yellow: PaletteOptions['primary'];
    purple: PaletteOptions['primary'];
    blue: PaletteOptions['primary'];
    black: PaletteOptions['primary'];
  }

  interface TypographyVariants {
    'h1.merlodnorme': React.CSSProperties;
    'h1.caveat': React.CSSProperties;
    'h2.merlodnorme': React.CSSProperties;
    'h2.caveat': React.CSSProperties;
    'h3.merlodnorme': React.CSSProperties;
    'h3.caveat': React.CSSProperties;
    'h4.merlodnorme': React.CSSProperties;
    'h4.caveat': React.CSSProperties;
    'h4.futuranowtext': React.CSSProperties;
    'h5.merlodnorme': React.CSSProperties;
    'h5.caveat': React.CSSProperties;
    'h5.futuranowtext': React.CSSProperties;
    'h6.merlodnorme': React.CSSProperties;
    'h6.caveat': React.CSSProperties;
    'h6.futuranowtext': React.CSSProperties;
    'h7.futuranowtext': React.CSSProperties;
    'h7.caveat': React.CSSProperties;
    'h8.futuranowtext': React.CSSProperties;
    'h8.caveat': React.CSSProperties;
    'h9.futuranowtext': React.CSSProperties;
    'h10.futuranowtext': React.CSSProperties;
    yum: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    'h1.merlodnorme': React.CSSProperties;
    'h1.caveat': React.CSSProperties;
    'h2.merlodnorme': React.CSSProperties;
    'h2.caveat': React.CSSProperties;
    'h3.merlodnorme': React.CSSProperties;
    'h3.caveat': React.CSSProperties;
    'h4.merlodnorme': React.CSSProperties;
    'h4.caveat': React.CSSProperties;
    'h4.futuranowtext': React.CSSProperties;
    'h5.merlodnorme': React.CSSProperties;
    'h5.caveat': React.CSSProperties;
    'h5.futuranowtext': React.CSSProperties;
    'h6.merlodnorme': React.CSSProperties;
    'h6.caveat': React.CSSProperties;
    'h6.futuranowtext': React.CSSProperties;
    'h7.futuranowtext': React.CSSProperties;
    'h7.caveat': React.CSSProperties;
    'h8.futuranowtext': React.CSSProperties;
    'h8.caveat': React.CSSProperties;
    'h9.futuranowtext': React.CSSProperties;
    'h10.futuranowtext': React.CSSProperties;
    yum: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'h1.merlodnorme': true;
    'h1.caveat': true;
    'h2.merlodnorme': true;
    'h2.caveat': true;
    'h3.merlodnorme': true;
    'h3.caveat': true;
    'h4.merlodnorme': true;
    'h4.caveat': true;
    'h4.futuranowtext': true;
    'h5.merlodnorme': true;
    'h5.caveat': true;
    'h5.futuranowtext': true;
    'h6.merlodnorme': true;
    'h6.caveat': true;
    'h6.futuranowtext': true;
    'h7.futuranowtext': true;
    'h7.caveat': true;
    'h8.futuranowtext': true;
    'h8.caveat': true;
    'h9.futuranowtext': true;
    'h10.futuranowtext': true;
    yum: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    /* LEGACY START */
    [Color.PRIMARY]: true;
    [Color.SECONDARY]: true;
    [Color.TERTIARY]: true;
    [Color.QUATERNARY]: true;
    [Color.DANGER]: true;
    [Color.DANGER_ALT]: true;
    [Color.SUCCESS]: true;
    [Color.SUCCESS_ALT]: true;
    [Color.SUNNY]: true;
    [Color.LIGHT]: true;
    [Color.PRIMARY_ALT]: true;
    [Color.SECONDARY_ALT]: true;
    [Color.TERTIARY_ALT]: true;
    [Color.QUATERNARY_ALT]: true;
    [Color.SUNNY_ALT]: true;
    [Color.DARK_ALT]: true;
    [Color.GRAY_100]: true;
    [Color.GRAY_200]: true;
    [Color.GRAY_300]: true;
    [Color.GRAY_400]: true;
    [Color.GRAY_500]: true;
    [Color.DARK]: true;
    [Color.DISABLED]: true;
    [Color.POULTRY]: true;
    [Color.GLUTEN_FREE]: true;
    [Color.RED_MEAT]: true;
    [Color.SEA_SHELL]: true;
    [Color.LACTOSE_FREE]: true;
    [Color.PORK]: true;
    [Color.RABBIT]: true;
    [Color.VEGGIE]: true;
    [Color.SPICY]: true;
    [Color.ALCOHOL]: true;
    [Color.TWITTER]: true;
    [Color.INSTAGRAM]: true;
    [Color.FACEBOOK]: true;
    [Color.TABLE_ROW]: true;
    [Color.PRIMARY_LIGHT]: true;
    [Color.SECONDARY_LIGHT]: true;
    [Color.TERTIARY_LIGHT]: true;
    [Color.QUATERNARY_LIGHT]: true;
    [Color.SUNNY_LIGHT]: true;
    [Color.DANGER_LIGHT]: true;
    [Color.SUCCESS_LIGHT]: true;
    [Color.DARK_LIGHT]: true;
    [Color.DISABLED_LIGHT]: true;
    [Color.POULTRY_LIGHT]: true;
    [Color.GLUTEN_FREE_LIGHT]: true;
    [Color.RED_MEAT_LIGHT]: true;
    [Color.SEA_SHELL_LIGHT]: true;
    [Color.LACTOSE_FREE_LIGHT]: true;
    [Color.PORK_LIGHT]: true;
    [Color.RABBIT_LIGHT]: true;
    [Color.VEGGIE_LIGHT]: true;
    [Color.SPICY_LIGHT]: true;
    [Color.ALCOHOL_LIGHT]: true;
    [Color.EXPIRATION_SALE_BACKGROUND]: true;
    /* LEGACY END */

    orange: true;
    green: true;
    yellow: true;
    purple: true;
    blue: true;
    black: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    outlined: true;
  }

  interface IconButtonPropsColorOverrides {
    /* LEGACY START */
    danger: true;
    light: true;
    poultry: true;
    /* LEGACY END */
    orange: true;
    green: true;
    yellow: true;
    purple: true;
    blue: true;
    black: true;
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    black: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    orange: true;
    green: true;
    yellow: true;
    purple: true;
    blue: true;
    black: true;
  }
}

const primary: PaletteColor = {
  // Unused
  light: palette.orange.pastel,
  // Unused
  dark: palette.orange.main,

  main: palette.orange.main,
  contrastText: palette.common.white,
  medium: palette.orange.medium,
  mediumContrastText: palette.common.black,
  pastel: palette.orange.pastel,
  pastelContrastText: palette.common.black,
};

const secondary: PaletteColor = {
  // Unused
  light: palette.yellow.pastel,
  // Unused
  dark: palette.yellow.main,

  main: palette.yellow.main,
  contrastText: palette.yellow.contrastText,
  medium: palette.yellow.medium,
  mediumContrastText: palette.common.black,
  pastel: palette.yellow.pastel,
  pastelContrastText: palette.common.black,
};

const theme = createTheme({
  cssVariables: false,
  sizing: {
    tabHeight: {
      lg: '150px',
      sm: '72px',
    },
  },
  colorSchemes: {
    light: {
      palette: {
        mode: 'light',
        ...palette,
        primary,
        secondary,
      },
    },
    // dark: {
    //   palette: {
    //     mode: 'dark',
    //     ...palette,
    //     text: {
    //       primary: palette.common.white,
    //       secondary: palette.common.white,
    //       disabled: palette.grey[500],
    //     },
    //     primary,
    //     secondary,
    //     background: {
    //       default: palette.common.black,
    //       paper: '#0A0A0E',
    //     },
    //   },
    // },
  },
  shadows,
  typography,
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          'h1.merlodnorme': 'h1',
          'h1.caveat': 'h1',
          'h2.merlodnorme': 'h2',
          'h2.caveat': 'h2',
          'h3.merlodnorme': 'h3',
          'h3.caveat': 'h3',
          'h4.merlodnorme': 'h4',
          'h4.caveat': 'h4',
          'h4.futuranowtext': 'h4',
          'h5.merlodnorme': 'h5',
          'h5.caveat': 'h5',
          'h5.futuranowtext': 'h5',
          'h6.merlodnorme': 'h6',
          'h6.caveat': 'h6',
          'h6.futuranowtext': 'h6',
          'h7.futuranowtext': 'p',
          'h7.caveat': 'p',
          'h8.futuranowtext': 'p',
          'h8.caveat': 'p',
          'h9.futuranowtext': 'p',
          'h10.futuranowtext': 'p',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      // Override to make the main color shade work
      // To use other shade use Button from the common package
      styleOverrides: {
        root: ({
          ownerState,
          theme,
        }: {
          ownerState: ButtonProps;
          theme: Theme;
        }) => ({
          borderRadius: '100vh',
          padding: '8px 24px',
          transformStyle: 'preserve-3d',
          '&:hover': {
            borderWidth: '2px',
          },
          '&:focus': {
            outlineWidth: '2px',
            outlineStyle: 'solid',
            outlineColor: getButtonColor(theme, ownerState.color, 'medium'),
            outlineOffset: '3px',
          },
          disabled: {
            color: palette.grey[400],
          },
        }),
        sizeSmall: {
          fontSize: '14px',
          lineHeight: '20px',
          padding: '6px 16px',
        },
        sizeLarge: {
          fontSize: '20px',
          lineHeight: '30px',
          letterSpacing: '2px',
          padding: '15px 24px',
        },
        text: ({
          ownerState,
          theme,
        }: {
          ownerState: ButtonProps;
          theme: Theme;
        }) => ({
          '&:active': {
            color: getButtonColor(theme, ownerState.color),
          },
          '&:disabled': {
            color: palette.grey[400],
          },
          '&& .MuiTouchRipple-root': {
            color: 'inherit',
            display: 'none',
          },
        }),
        outlined: {
          borderColor: 'currentColor',
          borderWidth: '2px',
        },
        contained: ({
          ownerState,
          theme,
        }: {
          ownerState: ButtonProps;
          theme: Theme;
        }) => ({
          '&:active': {
            color: getContrastTextColor(theme, ownerState.color),
            '@media (hover: none)': {
              backgroundColor: getButtonColor(theme, ownerState.color),
            },
          },
          '&:disabled': {
            backgroundColor: palette.grey[300],
            color: palette.grey[500],
          },
          // Override to make the ripple non opaque under the text
          '@keyframes foodles-ripple-enter-keyframes': {
            '0%': { transform: 'scale(0)', opacity: 0.1, zIndex: -1 },
            '100%': { transform: 'scale(1)', opacity: 1, zIndex: -1 },
          },
          '&& .MuiTouchRipple-root': {
            color: getButtonColor(theme, ownerState.color),
            zIndex: -1,
            '@media (hover: none)': {
              color: getButtonColor(theme, ownerState.color, 'medium'),
            },
            '.MuiTouchRipple-ripple': {
              opacity: 1,
              animationName: 'foodles-ripple-enter-keyframes',
            },
          },
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: 'none',
          boxShadow: 'none',

          ':before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});

theme.spacing(2);

export type AppTheme = typeof theme;
export default theme;
