import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Merlod Norme';
    src: url('/assets/fonts/Merlod-Norme/MerlodNorme-Bold.otf') format('truetype');
  }

  @font-face {
    font-family: 'Futura Now Text';
    src: url('/assets/fonts/Futura-Now-Text/FuturaNowText-Rg.otf') format('truetype');
  }

  html, body {
    height: 100%;
    margin: 0;
  }

  html {
    scroll-behavior: smooth;
    font-size: 16px;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif, "Segoe UI", Roboto, Ubuntu;
  }

  table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  a,
  a:visited,
  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: 1024px) {
    .intercom-lightweight-app-launcher {
      bottom: 84px !important;
    }
  }
`;

export default GlobalStyles;
